@font-face {
  font-family: 'Circular Std Book';
  src: url('font/CircularStd-Book.eot');
  src: url('font/CircularStd-Book.eot?#iefix') format('embedded-opentype'),
      url('font/CircularStd-Book.woff2') format('woff2'),
      url('font/CircularStd-Book.woff') format('woff'),
      url('font/CircularStd-Book.ttf') format('truetype'),
      url('font/CircularStd-Book.svg#CircularStd-Book') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Circular Std';
  src: url('font/CircularStd-Black.eot');
  src: url('font/CircularStd-Black.eot?#iefix') format('embedded-opentype'),
      url('font/CircularStd-Black.woff2') format('woff2'),
      url('font/CircularStd-Black.woff') format('woff'),
      url('font/CircularStd-Black.ttf') format('truetype'),
      url('font/CircularStd-Black.svg#CircularStd-Black') format('svg');
  font-weight: 900;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Circular Std Book';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

h1 {
  font-size: 60px;
  font-family: 'Circular Std';
}

h1, h2, h3, h4, h5 {
  margin: 0;
  padding: 0;
}

@media (max-width: 376px) {
  h1 {
    font-size: 40px;
  }
}